#footer-container {
    background-color: #0a1f63;
    height: 4.5rem;
    font-size: 12px;
    text-align: center;
    bottom: 0;
    width: 100%;
}

#freepik {
    color: white !important;
}
